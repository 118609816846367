import PartySocket from 'partysocket'
import { ulid } from 'ulid'

declare const PARTYKIT_HOST: string

const urlParams = new URLSearchParams(location.search)
const room = urlParams.get('room')
if (!room) throw new Error('missing room')
const option = urlParams.get('option')
if (!option) throw new Error('missing option')

let user = localStorage.getItem('user')
if (!user) {
	user = ulid()
	localStorage.setItem('user', user)
}

const conn = new PartySocket({
	host: PARTYKIT_HOST,
	room,
	id: user,
})

conn.send(option)

conn.addEventListener('message', (event) => {
	const votes: Record<string, string[]> = JSON.parse(event.data)
	const optionVote = votes[option]?.length ?? 1
	document.querySelector('#text')!.textContent = `You and ${optionVote - 1} others voted for ${option}`
})
